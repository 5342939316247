<template>
  <div>
    <Headers></Headers>
    
    <div class="main-container">
      <div class="xs-pd-20-10 pd-ltr-20">
        <div class="title pb-20">
          <h2 class="h3 mb-0">Tableau de bord</h2>
        </div>

        <div class="row pb-10">
          <div class="col-xl-3 col-lg-3 col-md-6 mb-20">
            <div class="card-box height-100-p widget-style3">
              <div class="d-flex flex-wrap">
                <div class="widget-data">
                  <div class="weight-700 font-24 text-dark">75</div>
                  <div class="font-14 text-secondary weight-500">Clients</div>
                </div>
                <div class="widget-icon"></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 mb-20">
            <div class="card-box height-100-p widget-style3">
              <div class="d-flex flex-wrap">
                <div class="widget-data">
                  <div class="weight-700 font-24 text-dark">124</div>
                  <div class="font-14 text-secondary weight-500">
                    Agent collecteure
                  </div>
                </div>
                <div class="widget-icon"></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 mb-20">
            <div class="card-box height-100-p widget-style3">
              <div class="d-flex flex-wrap">
                <div class="widget-data">
                  <div class="weight-700 font-24 text-dark">400</div>
                  <div class="font-14 text-secondary weight-500">
                    Agences créer
                  </div>
                </div>
                <div class="widget-icon"></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 mb-20">
            <div class="card-box height-100-p widget-style3">
              <div class="d-flex flex-wrap">
                <div class="widget-data">
                  <div class="weight-700 font-24 text-dark">50</div>
                  <div class="font-14 text-secondary weight-500">
                    Nombre de carnet
                  </div>
                </div>
                <div class="widget-icon"></div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 mb-20">
            <div class="card-box height-100-p widget-style3">
              <div class="d-flex flex-wrap">
                <div class="widget-data">
                  <div class="weight-700 font-24 text-dark">400</div>
                  <div class="font-14 text-secondary weight-500">Gerants</div>
                </div>
                <div class="widget-icon"></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 mb-20">
            <div class="card-box height-100-p widget-style3">
              <div class="d-flex flex-wrap">
                <div class="widget-data">
                  <div class="weight-700 font-24 text-dark">50</div>
                  <div class="font-14 text-secondary weight-500">
                    Nombre de carnet
                  </div>
                </div>
                <div class="widget-icon"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-box pb-10">
          <div class="h5 pd-20 mb-0">Liste des collecteurs</div>
          <div class="table-responsive">
            <table class="data-table table nowrap display">
              <thead>
                <tr>
                  <th class="table-plus">Name</th>
                  <th>Gender</th>
                  <th>Weight</th>
                  <th>Assigned Doctor</th>
                  <th>Admit Date</th>
                  <th>Disease</th>
                  <th class="datatable-nosort">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="table-plus">
                    <div class="name-avatar d-flex align-items-center">
                      <div class="avatar mr-2 flex-shrink-0">
                        <img
                          src="/assets/vendors/images/photo4.jpg"
                          class="border-radius-100 shadow"
                          width="40"
                          height="40"
                          alt=""
                        />
                      </div>
                      <div class="txt">
                        <div class="weight-600">Jennifer O. Oster</div>
                      </div>
                    </div>
                  </td>
                  <td>Female</td>
                  <td>45 kg</td>
                  <td>Dr. Callie Reed</td>
                  <td>19 Oct 2020</td>
                  <td>
                    <span
                      class="badge badge-pill"
                      data-bgcolor="#e7ebf5"
                      data-color="#265ed7"
                      >Typhoid</span
                    >
                  </td>
                  <td>
                    <div class="table-actions">
                      <a href="#" data-color="#265ed7"
                        ><i class="icon-copy dw dw-edit2"></i
                      ></a>
                      <a href="#" data-color="#e95959"
                        ><i class="icon-copy dw dw-delete-3"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-responsive-bs4";
import Headers from "../layouts/header.vue";
export default {
  components: {
    Headers,
  },
  mounted() {
    $("table.display").DataTable({
      responsive: true,
      language: {
        url: "https://cdn.datatables.net/plug-ins/1.10.21/i18n/French.json",
      },
      scrollY: "450px",
      searching: false,
      paging: false,
      info: false,
    });
  },
};
</script>