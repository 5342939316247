<template>
  <div>
    <Headers></Headers>

    <div class="main-container">
      <div class="xs-pd-20-10 pd-ltr-20">
        <div class="title pb-20">
          <h2 class="h3 mb-0">Dépôts</h2>
        </div>

        <div class="card card-body pb-10">
          <div class="h5 pd-20 mb-0">
            Historiques des depots <span>{{ name }}</span>
          </div>
          <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-6 mb-20">
              <a href="#" @click="jour_card">
                <div class="card-box height-100-p widget-style3">
                  <div class="d-flex flex-wrap">
                    <div class="widget-data">
                      <div class="weight-700 font-24 text-dark">
                        {{ depot_jour }}
                      </div>
                      <div class="font-14 text-secondary weight-500">
                        Par jour
                      </div>
                    </div>
                    <div class="widget-icon bg-danger"></div>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 mb-20">
              <a href="#" @click="mois_card">
                <div class="card-box height-100-p widget-style3">
                  <div class="d-flex flex-wrap">
                    <div class="widget-data">
                      <div class="weight-700 font-24 text-dark">
                        {{ depot_mois }}
                      </div>
                      <div class="font-14 text-secondary weight-500">
                        Par mois
                      </div>
                    </div>
                    <div class="widget-icon bg-success"></div>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 mb-20">
              <a href="#" @click="year_card">
                <div class="card-box height-100-p widget-style3">
                  <div class="d-flex flex-wrap">
                    <div class="widget-data">
                      <div class="weight-700 font-24 text-dark">
                        {{ depot_year }}
                      </div>
                      <div class="font-14 text-secondary weight-500">
                        Par année
                      </div>
                    </div>
                    <div class="widget-icon bg-primary"></div>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-12 mt-4">
              <div class="table-responsive">
                <table class="table display table-striped table-hover">
                  <thead class="">
                    <tr>
                      <th>Date de depot</th>
                      <th>Sommes deposer</th>
                      <th>Nom Agence</th>
                      <th>Nom Gerant</th>
                      <th>Numero Gerant</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="depot in depots" :key="depot">
                      <td>{{ depot.created_at }}</td>
                      <td>{{ depot.montant }} Fcfa</td>
                      <td>{{ depot.agence.nom }}</td>
                      <td>{{ depot.agent.nom }} {{ depot.agent.prenom }}</td>
                      <td>{{ depot.agent.numero }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="delete"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header no-bd">
            <h5 class="modal-title text-danger">Supprimer le carnet</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="text-center modal-body">
            <p>
              Voulez vous vraimernt block ce collecteur? <br /><strong
                id="libelle"
              ></strong>
            </p>
          </div>
          <div class="modal-footer no-bd">
            <button
              type="button"
              id="addRowButton"
              @click="block"
              class="btn btn-danger"
            >
              Oui, Bloquée
            </button>
            <button type="button" class="btn btn-light" data-dismiss="modal">
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-responsive-bs4";
import Headers from "../layouts/header.vue";
import Swal from "sweetalert2";
export default {
  components: {
    Headers,
  },
  data() {
    return {
      token_admin: "",
      token_password: "",
      callback: "http://essoapp.iziway.tk/api/auth/admin",
      depots: [{}],
      depot_jour: null,
      depot_semaine: null,
      depot_mois: null,
      depot_year: null,
      name: null,
    };
  },
  mounted() {
    let session = localStorage;
    this.token_admin = session.getItem("token");

    let headers = { headers: { Authorization: this.token_admin } };

    this.$http
      .post(
        `${this.callback}/transaction/collecteur/${this.$route.params.id}`,
        {},
        headers
      )
      .then(
        (response) => {
          let data = response.body.data;
          this.depots = data;
        },
        (response) => {
          Swal.fire({
            title: "Oops...",
            text: response.body.message,
            icon: "error",
            showCancelButton: true,
            confirmButtonClass: "btn btn-success",
            cancelButtonClass: "btn btn-danger",
          });
        }
      );

    this.$http
      .post(
        `${this.callback}/transaction/collecteur/${this.$route.params.id}/statistique`,
        {},
        headers
      )
      .then(
        (response) => {
          let data = response.body.data;
          console.log(data);

          this.depot_jour = data.jour.length;
          this.depot_semaine = data.semaine.length;
          this.depot_mois = data.mois.length;
          this.depot_year = data.annee.length;
        },
        (response) => {
          Swal.fire({
            title: "Oops...",
            text: response.body.message,
            icon: "error",
            showCancelButton: true,
            confirmButtonClass: "btn btn-success",
            cancelButtonClass: "btn btn-danger",
          });
        }
      );

    setTimeout(() => {
      $("table.display").DataTable({
        language: {
          url: "https://cdn.datatables.net/plug-ins/1.10.21/i18n/French.json",
        },
        scrollY: "250px",
        order: [[0, "desc"]],
      });
    }, 1000);
  },
  methods: {
    jour_card() {
      let session = localStorage;
      this.token_admin = session.getItem("token");

      let headers = { headers: { Authorization: this.token_admin } };

      this.$http
        .post(
          `${this.callback}/transaction/collecteur/${this.$route.params.id}/statistique`,
          {},
          headers
        )
        .then(
          (response) => {
            let data = response.body.data;
            this.depots = data.jour;
            this.name = "par jour";

            if (this.depots.length == 0) {
              Swal.fire({
                title: "Historique vide",
                text: `Historique des depots ${this.name} est vide`,
                icon: "error",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
              });
            }
          },
          (response) => {
            Swal.fire({
              title: "Oops...",
              text: response.body.message,
              icon: "error",
              showCancelButton: true,
              confirmButtonClass: "btn btn-success",
              cancelButtonClass: "btn btn-danger",
            });
          }
        );
    },

    mois_card() {
      let session = localStorage;
      this.token_admin = session.getItem("token");

      let headers = { headers: { Authorization: this.token_admin } };

      this.$http
        .post(
          `${this.callback}/transaction/collecteur/${this.$route.params.id}/statistique`,
          {},
          headers
        )
        .then(
          (response) => {
            let data = response.body.data;
            this.depots = data.mois;
            this.name = "par mois";

            if (this.depots.length == 0) {
              Swal.fire({
                title: "Historique vide",
                text: `Historique des depots ${this.name} est vide`,
                icon: "error",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
              });
            }
          },
          (response) => {
            Swal.fire({
              title: "Oops...",
              text: response.body.message,
              icon: "error",
              showCancelButton: true,
              confirmButtonClass: "btn btn-success",
              cancelButtonClass: "btn btn-danger",
            });
          }
        );
    },
    year_card() {
      let session = localStorage;
      this.token_admin = session.getItem("token");

      let headers = { headers: { Authorization: this.token_admin } };

      this.$http
        .post(
          `${this.callback}/transaction/collecteur/${this.$route.params.id}/statistique`,
          {},
          headers
        )
        .then(
          (response) => {
            let data = response.body.data;
            this.depots = data.annee;
            this.name = "par année";

            if (this.depots.length == 0) {
              Swal.fire({
                title: "Historique vide",
                text: `Historique des depots ${this.name} est vide`,
                icon: "error",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
              });
            }
          },
          (response) => {
            Swal.fire({
              title: "Oops...",
              text: response.body.message,
              icon: "error",
              showCancelButton: true,
              confirmButtonClass: "btn btn-success",
              cancelButtonClass: "btn btn-danger",
            });
          }
        );
    },
  },
};
</script>