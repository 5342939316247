<template>
  <div class="login-page">
    <div class="login-header box-shadow">
      <div
        class="
          container-fluid
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <div class="brand-logo">
          <a href="login.html">
            <img src="/assets/vendors/images/deskapp-logo.svg" alt="" />
          </a>
        </div>
        <div class="login-menu"></div>
      </div>
    </div>
    <div
      class="
        login-wrap
        d-flex
        align-items-center
        flex-wrap
        justify-content-center
      "
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-7">
            <img src="/assets/vendors/images/login-page-img.png" alt="" />
          </div>
          <div class="col-md-6 col-lg-5 mt-4">
            <div class="login-box bg-white box-shadow border-radius-10">
              <div class="login-title">
                <h2 class="text-center text-primary">Connexion à IziWay</h2>
              </div>
              <form @submit.prevent="login_submit()">
                <div class="select-role">
                  <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn">
                      <input type="radio" name="options" id="user" />
                      <div class="icon">
                        <img
                          src="/assets/vendors/images/person.svg"
                          class="svg"
                          alt=""
                        />
                      </div>
                      <span>Connexion</span>
                      Administration
                    </label>
                  </div>
                </div>
                <div class="input-group custom">
                  <input
                    type="email"
                    v-model="email"
                    class="form-control form-control-lg"
                    placeholder="Adresse email"
                  />
                  <div class="input-group-append custom">
                    <span class="input-group-text"
                      ><i class="icon-copy dw dw-user1"></i
                    ></span>
                  </div>
                </div>
                <div class="input-group custom">
                  <input
                    type="password"
                    v-model="password"
                    class="form-control form-control-lg"
                    placeholder="**********"
                  />
                  <div class="input-group-append custom">
                    <span class="input-group-text"
                      ><i class="dw dw-padlock1"></i
                    ></span>
                  </div>
                </div>
                <div class="row pb-30"></div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="input-group mb-0">
                      <button
                        type="submit"
                        class="btn btn-primary btn-lg btn-block"
                      >
                        Connexion
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import $ from "jquery";

export default {
  data() {
    return {
      callback: "http://essoapp.iziway.tk/api/auth/admin",
      email: "",
      password: "",
    };
  },
  mounted() {
    let session = localStorage;

    if (session.getItem("token")) {
      this.$router.push({ name: "dashboard" });
    }

    $("#show").on("click", () => {
      if ($("#password").attr("type") == "password") {
        $("#password").attr("type", "text");
      } else {
        $("#password").attr("type", "password");
      }
    });
  },
  methods: {
    login_submit() {
      if (this.email != "" && this.password != "") {
        const data = { email: this.email, password: this.password };

        this.$http.post(`${this.callback}/login`, data).then(
          (response) => {
            let session = localStorage;

            console.log(response);
            if (response.body.status == true) {
              session.setItem(
                "token",
                `${response.body.token_type} ${response.body.access_token}`
              );
              session.setItem("id", response.body.info.id);
              session.setItem("username", response.body.info.username);
              session.setItem("code_secret", response.body.info.code_secret);
              session.setItem(
                "type",
                response.body.info.id_type_administrateur
              );

              this.$router.go({path: '/dashboard'});
            } else {
              swal.fire({
                title: "Oops...",
                text: response.body.message,
                icon: "error",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
              });
            }
          },
          (response) => {
            console.log(response);

            swal.fire({
              title: "Oops...",
              text: "Erreur lors de la connexion",
              icon: "error",
              showCancelButton: true,
              confirmButtonClass: "btn btn-success",
              cancelButtonClass: "btn btn-danger",
            });
          }
        );
      } else {
        swal.fire({
          title: "Oops...",
          text: "Veillez remplir tous les champs",
          icon: "error",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
        });
      }
    },
  },
};
</script>