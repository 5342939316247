<template>
  <div>
    <Headers></Headers>

    <div class="main-container">
      <div class="xs-pd-20-10 pd-ltr-20">
        <div class="title pb-20">
          <h2 class="h3 mb-0">Détail du collecteur</h2>
        </div>

        <div class="card card-body pb-10">
          <div class="row">
            <div class="col-md-12 mb-4 d-flex justify-content-end">
              <a href="collecteur" class="btn btn-dark btn-sm"> Retour </a>
            </div>
            <div class="my-4 col-md-5">
              <div class="row">
                <div class="my-2 col-md-12">
                  <p>
                    Date de creation:
                    <strong>{{ collecteur.updated_at }}</strong>
                  </p>
                </div>
                <div class="my-2 col-md-6">
                  <p>
                    Nom: <strong>{{ collecteur.nom }}</strong>
                  </p>
                </div>
                <div class="my-2 col-md-6">
                  <p>
                    Prenom: <strong>{{ collecteur.prenom }}</strong>
                  </p>
                </div>
                <div class="my-2 col-md-12">
                  <p>
                    Numero de téléphone:
                    <strong>{{ collecteur.numero }}</strong>
                  </p>
                </div>
                <div class="my-2 col-md-12">
                  <p>
                    Username: <strong>{{ collecteur.username }}</strong>
                  </p>
                </div>
                <div class="my-2 col-md-12">
                  <p>
                    Code secret:
                    <strong>{{ collecteur.code_secret }}</strong>
                  </p>
                </div>
                <div class="my-2 col-md-6">
                  <p>
                    Ville: <strong>{{ ville }}</strong>
                  </p>
                </div>
                <div class="my-2 col-md-6">
                  <p>
                    Quartier: <strong>{{ quartier }}</strong>
                  </p>
                </div>
              </div>
              <div class="my-4 row">
                <div class="col-md-6">
                  <p class="text-primary">
                    Changer ce collecteur par un autre collecteur
                  </p>
                  <div class="p-2 card bg-light">
                    <form action="" method="post" @submit.prevent="deleguer">
                      <div class="form-group">
                        <label for="">Nouveau collecteur</label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          v-model="id_collecteur"
                        >
                          <option
                            v-bind:value="collecteur.id"
                            v-for="collecteur in collecteurs"
                            :key="collecteur"
                          >
                            {{ collecteur.nom }} {{ collecteur.prenom }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="">Votre code secret</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Code secret"
                          v-model="code"
                        />
                      </div>
                      <div class="text-right form-group">
                        <button class="btn btn-sm btn-primary">Affecter</button>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-6" v-if="collecteur.dette != 0">
                  <h5 class="text-primary">Solder la dette du collecteur</h5>
                  <div class="p-2 card bg-light">
                    <form
                      action=""
                      method="post"
                      @submit.prevent="submit_dette"
                    >
                      <div class="form-group">
                        <label for="">Code secret</label>
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Votre code secret"
                          v-model="code"
                          name=""
                          id=""
                        />
                      </div>
                      <div class="text-right form-group">
                        <button type="submit" class="btn btn-primary btn-sm">
                          Solder la dette
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div class="mt-4 col-md-6">
                  <form action="" @submit.prevent="disconnect">
                    <div class="form-group">
                      <label for="">Votre code secret</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Code secret"
                        v-model="code"
                      />
                    </div>
                    <div class="text-right form-group">
                      <button type="submit" class="btn btn-sm btn-danger">
                        Deconnecter
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <a v-bind:href="'collecteurdepot'+collecteur.id"
                    style="text-decoration: none"
                  >
                    <div class="card card-stats bg-light card-round">
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="ml-3 col col-stats ml-sm-0">
                            <div class="numbers">
                              <p class="card-category">Versement effectuer</p>
                              <p class="card-title">{{ depot }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="card card-stats bg-light card-round">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="ml-3 col col-stats ml-sm-0">
                          <div class="numbers">
                            <p class="card-category">Nombre de clients</p>
                            <p class="card-title">
                              {{ clients.length }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4 col-sm-12 col-md-6">
                  <div class="card card-stats bg-light card-round">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="ml-3 col col-stats ml-sm-0">
                          <div class="numbers">
                            <p class="card-category">Carnet vendus total</p>
                            <p class="card-title">
                              {{ collecteur.carnet_vendu }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4 col-sm-12 col-md-6">
                  <div class="card card-stats bg-light card-round">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="ml-3 col col-stats ml-sm-0">
                          <div class="numbers">
                            <p class="card-category">Frais carnet au total</p>
                            <p class="card-title">
                              {{ collecteur.carnet_vendu * 200 }} Fcfa
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4 col-sm-12 col-md-6">
                  <div class="card card-stats bg-light card-round">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="ml-3 col col-stats ml-sm-0">
                          <div class="numbers">
                            <p class="card-category">Carnet vendus du jour</p>
                            <p class="card-title">
                              {{ collecteur.carnet_vendu_jour }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4 col-sm-12 col-md-6">
                  <div class="card card-stats bg-light card-round">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="ml-3 col col-stats ml-sm-0">
                          <div class="numbers">
                            <p class="card-category">Dette du collecteur</p>
                            <p class="card-title">
                              {{ collecteur.dette }} Fcfa
                            </p>
                            <div
                              class="text-right"
                              v-if="collecteur.dette != 0"
                            >
                              <button
                                class="btn btn-xs btn-info"
                                @click="print('#print')"
                              >
                                <i class="fa fa-print"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="display: none">
                  <div id="print">
                    <div class="row">
                      <div class="my-4 col-md-12 bg-primary">
                        <h2 class="text-center text-primary font-weight-bold">
                          IZIWAY - Remboursement - Collecteur
                        </h2>
                      </div>
                      <div class="my-2 col-md-12">
                        <h3>
                          Date de creation:
                          <strong>{{ collecteur.updated_at }}</strong>
                        </h3>
                      </div>
                      <div class="my-2 col-md-6">
                        <h3>
                          Nom: <strong>{{ collecteur.nom }}</strong>
                        </h3>
                      </div>
                      <div class="my-2 col-md-6">
                        <h3>
                          Prenom:
                          <strong>{{ collecteur.prenom }}</strong>
                        </h3>
                      </div>
                      <div class="my-2 col-md-6">
                        <h3>
                          Numero de téléphone:
                          <strong>{{ collecteur.numero }}</strong>
                        </h3>
                      </div>
                      <div class="my-2 col-md-6">
                        <h3>
                          Username:
                          <strong>{{ collecteur.username }}</strong>
                        </h3>
                      </div>
                      <div class="my-2 col-md-6">
                        <h3>
                          Ville: <strong>{{ ville }}</strong>
                        </h3>
                      </div>
                      <div class="my-2 col-md-6">
                        <h3>
                          Quartier: <strong>{{ quartier }}</strong>
                        </h3>
                      </div>
                      <div class="my-4 col-md-6">
                        <h1>
                          Somme à rembourser:
                          <strong>{{ collecteur.dette }} Fcfa</strong>
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-4 col-sm-12 col-md-6">
                  <div class="card card-stats bg-light card-round">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col-icon">
                          <div
                            class="
                              text-center
                              icon-big icon-info
                              bubble-shadow-small
                            "
                          >
                            <i class="fab fa-accusoft"></i>
                          </div>
                        </div>
                        <div class="ml-3 col col-stats ml-sm-0">
                          <div class="numbers">
                            <p class="card-category">Frais du carnet du jour</p>
                            <p class="card-title">
                              {{ collecteur.carnet_vendu_jour * 200 }}
                              Fcfa
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4 col-sm-12 col-md-6"></div>
                <div class="mt-4 col-sm-12 col-md-6">
                  <div class="card card-stats bg-light card-round">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col-icon">
                          <div
                            class="
                              text-center
                              icon-big icon-info
                              bubble-shadow-small
                            "
                          >
                            <i class="fa fa-hand-holding-usd"></i>
                          </div>
                        </div>
                        <div class="ml-3 col col-stats ml-sm-0">
                          <div class="numbers">
                            <p class="card-category">
                              Frais total des cotisations
                            </p>
                            <p class="card-title">
                              {{
                                collecteur.total_cotisation +
                                collecteur.compte_agent_collecteur
                              }}
                              Fcfa
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4 col-sm-12 col-md-6">
                  <div class="card card-stats bg-light card-round">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col-icon">
                          <div
                            class="
                              text-center
                              icon-big icon-info
                              bubble-shadow-small
                            "
                          >
                            <i class="fa fa-layer-group"></i>
                          </div>
                        </div>
                        <div class="ml-3 col col-stats ml-sm-0">
                          <div class="numbers">
                            <p class="card-category">
                              Frais total des versements
                            </p>
                            <p class="card-title">
                              {{ collecteur.total_cotisation }}
                              Fcfa
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-4 col-md-6">
                  <form action="" @submit.prevent="code_secret_change">
                    <div class="form-group">
                      <label for="">Code secret generer</label>
                      <input
                        type="text"
                        v-model="code_secret"
                        class="form-control"
                        disabled
                      />
                    </div>
                    <div class="text-right form-group">
                      <button type="submit" class="btn btn-sm btn-primary">
                        Generer
                      </button>
                    </div>
                  </form>
                </div>
                <div class="mt-4 col-md-6">
                  <form action="" @submit.prevent="password_change">
                    <div class="form-group">
                      <label for="">Mot de passe generer</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="password"
                        disabled
                      />
                    </div>
                    <div class="text-right form-group">
                      <button type="submit" class="btn btn-sm btn-primary">
                        Generer
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="my-4 col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <p class="text-left text-primary">
                    Liste des client prisent en charge
                  </p>
                </div>
                <div class="table-responsive" v-if="load == 1">
                  <table
                    id="basic-datatables"
                    class="table display table-striped table-hover"
                  >
                    <thead class="">
                      <tr>
                        <th>Date creation</th>
                        <th>Nom & Prenom</th>
                        <th>Numero de téléphone</th>
                        <th>Quartier</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="client in clients" :key="client.id">
                        <td>{{ client.updated_at }}</td>
                        <td>{{ client.nom }} {{ client.prenom }}</td>
                        <td>+228 {{ client.numero }}</td>
                        <td>Totsi</td>
                        <td>
                          <router-link
                            :to="{
                              name: 'client_detail',
                              params: { id: client.id },
                            }"
                            class="btn btn-primary btn-sm"
                          >
                            <i class="fa fa-arrow-right"></i> Détail
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div v-if="load == 0">
                  <div
                    class="
                      load
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                    style="height: 30vh; width: 90vw"
                  >
                    <looping-rhombuses-spinner
                      :animation-duration="2500"
                      :rhombus-size="15"
                      color="#3570e3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-responsive-bs4";
import Headers from "../layouts/header.vue";
import Swal from "sweetalert2";
export default {
  components: {
    Headers,
  },
  data() {
    return {
      load: 0,
      token_admin: "",
      token_password: "",
      callback: "http://essoapp.iziway.tk/api/auth/admin",
      collecteur: {},
      collecteurs: [],
      id_collecteur: null,
      ville: null,
      quartier: null,
      clients: [{}],
      code_secret: null,
      code: null,
      password: null,
      depot: null,
    };
  },
  mounted() {
    let session = localStorage;
    this.token_admin = session.getItem("token");

    let headers = { headers: { Authorization: this.token_admin } };

    this.$http.post(`${this.callback}/agent_collecteur/list`, {}, headers).then(
      (response) => {
        let data = response.body.data;
        this.collecteurs = data;
      },
      (response) => {
        Swal.fire({
          title: "Oops...",
          text: response.body.message,
          icon: "error",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
        });
      }
    );

    this.$http
      .post(
        `${this.callback}/agent_collecteur/info/${this.$route.params.id}`,
        {},
        headers
      )
      .then(
        (response) => {
          let data = response.body.data;
          this.collecteur = data;
          this.ville = data.quartier.ville.libelle;
          this.quartier = data.quartier.libelle;

          console.log(data);
        },
        (response) => {
          Swal.fire({
            title: "Oops...",
            text: response.body.message,
            icon: "error",
            showCancelButton: true,
            confirmButtonClass: "btn btn-success",
            cancelButtonClass: "btn btn-danger",
          });
        }
      );

    this.$http
      .post(
        `${this.callback}/transaction/collecteur/${this.$route.params.id}`,
        {},
        headers
      )
      .then(
        (response) => {
          let data = response.body.data;
          this.depot = data.length;
        },
        (response) => {
          Swal.fire({
            title: "Oops...",
            text: response.body.message,
            icon: "error",
            showCancelButton: true,
            confirmButtonClass: "btn btn-success",
            cancelButtonClass: "btn btn-danger",
          });
        }
      );

    this.$http
      .post(
        `${this.callback}/agent_collecteur/client/${this.$route.params.id}`,
        {},
        headers
      )
      .then(
        (response) => {
          let data = response.body.data;
          this.clients = data;

          this.load = 1;
          setTimeout(() => {
            $("table.display").DataTable({
              language: {
                url: "https://cdn.datatables.net/plug-ins/1.10.21/i18n/French.json",
              },
              scrollY: "400px",
            });
          }, 1000);
        },
        (response) => {
          Swal.fire({
            title: "Oops...",
            text: response.body.message,
            icon: "error",
            showCancelButton: true,
            confirmButtonClass: "btn btn-success",
            cancelButtonClass: "btn btn-danger",
          });
        }
      );

    this.code_secret = Math.floor(Math.random() * (9999 - 1000) + 1000);
    this.password = `iziway@${Math.floor(
      Math.random() * (9999 - 1000) + 1000
    )}`;

    this.$http
      .post(
        `${this.callback}/agent_collecteur/${this.$route.params.id}/password/change/token`,
        {},
        headers
      )
      .then(
        (response) => {
          let data = response.body.data;

          this.token_password = data;
        },
        (response) => {
          Swal.fire({
            title: "Oops...",
            text: response.body.message,
            icon: "error",
            showCancelButton: true,
            confirmButtonClass: "btn btn-success",
            cancelButtonClass: "btn btn-danger",
          });
        }
      );
  },
  methods: {
    print(div) {
      $(div).print();
    },
    submit_dette() {
      if (this.code == localStorage.getItem("code_secret")) {
        let session = localStorage;
        this.token_admin = session.getItem("token");

        let headers = { headers: { Authorization: this.token_admin } };

        this.$http
          .post(
            `${this.callback}/agent_collecteur/init/dette/${this.$route.params.id}`,
            {},
            headers
          )
          .then(
            (response) => {
              let data = response.body;

              Swal.fire({
                title: "Success...",
                text: data.message,
                icon: "success",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
              });
            },
            (response) => {
              Swal.fire({
                title: "Oops...",
                text: response.body.message,
                icon: "error",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
              });
            }
          );
      } else {
        Swal.fire({
          title: "Oops...",
          text: "Code secret incorecte",
          icon: "error",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
        });
      }
    },
    code_secret_change() {
      let session = localStorage;
      this.token_admin = session.getItem("token");

      let headers = { headers: { Authorization: this.token_admin } };

      let data_param = {
        code_secret: this.collecteur.code_secret,
        newcode_secret: this.code_secret,
      };

      this.$http
        .post(
          `${this.callback}/agent_collecteur/${this.$route.params.id}/code/change`,
          data_param,
          headers
        )
        .then(
          (response) => {
            let data = response.body;

            if (data.status == true) {
              Swal.fire({
                title: "Success...",
                text: data.message,
                icon: "success",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
              });
              setTimeout(() => {
                window.location.reload();
              }, 2100);
            } else {
              Swal.fire({
                title: "Success...",
                text: data.message,
                icon: "success",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
              });
            }
          },
          (response) => {
            Swal.fire({
              title: "Oops...",
              text: response.body.message,
              icon: "error",
              showCancelButton: true,
              confirmButtonClass: "btn btn-success",
              cancelButtonClass: "btn btn-danger",
            });
          }
        );
    },
    password_change() {
      let session = localStorage;
      this.token_admin = session.getItem("token");

      let headers = { headers: { Authorization: this.token_password } };

      let data_param = {
        newpassword: this.password,
      };

      this.$http
        .post(
          `${this.callback}/agent_collecteur/${this.$route.params.id}/password/change/operation`,
          data_param,
          headers
        )
        .then(
          (response) => {
            let data = response.body;

            if (data.status == true) {
              Swal.fire({
                title: "Success...",
                text: data.message,
                icon: "success",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
              });
              setTimeout(() => {
                window.location.reload();
              }, 2100);
            } else {
              Swal.fire({
                title: "Success...",
                text: data.message,
                icon: "success",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
              });
            }
          },
          (response) => {
            Swal.fire({
              title: "Oops...",
              text: response.body.message,
              icon: "error",
              showCancelButton: true,
              confirmButtonClass: "btn btn-success",
              cancelButtonClass: "btn btn-danger",
            });
          }
        );
    },
    deleguer() {
      let session = localStorage;
      this.token_admin = session.getItem("token");

      let headers = { headers: { Authorization: this.token_admin } };

      if (this.code == session.getItem("code_secret")) {
        if (this.id_collecteur != null) {
          this.$http
            .post(
              `${this.callback}/agent_collecteur/affect/agent1/${this.$route.params.id}/client-to-agent2/${this.id_collecteur}`,
              {},
              headers
            )
            .then(
              (response) => {
                let data = response.body;

                if (data.status == true) {
                  Swal.fire({
                    title: "Success...",
                    text: data.message,
                    icon: "success",
                    showCancelButton: true,
                    confirmButtonClass: "btn btn-success",
                    cancelButtonClass: "btn btn-danger",
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 2100);
                } else {
                  Swal.fire({
                    title: "Success...",
                    text: data.message,
                    icon: "success",
                    showCancelButton: true,
                    confirmButtonClass: "btn btn-success",
                    cancelButtonClass: "btn btn-danger",
                  });
                }
              },
              (response) => {
                Swal.fire({
                  title: "Oops...",
                  text: response.body.message,
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonClass: "btn btn-success",
                  cancelButtonClass: "btn btn-danger",
                });
              }
            );
        } else {
          Swal.fire({
            title: "Erreur...",
            text: "Veillez selectionner un collecteur",
            icon: "error",
            showCancelButton: true,
            confirmButtonClass: "btn btn-success",
            cancelButtonClass: "btn btn-danger",
          });
        }
      } else {
        Swal.fire({
          title: "Erreur...",
          text: "Code secret incorrect",
          icon: "success",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
        });
      }
    },
    submit_dette1() {
      if (this.code == localStorage.getItem("code_secret")) {
        let session = localStorage;
        this.token_admin = session.getItem("token");

        let headers = { headers: { Authorization: this.token_admin } };

        this.$http
          .post(
            `${this.callback}/agent_collecteur/init/dette/${this.$route.params.id}`,
            {},
            headers
          )
          .then(
            (response) => {
              let data = response.body;

              Swal.fire({
                title: "Success...",
                text: data.message,
                icon: "success",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
              });
            },
            (response) => {
              Swal.fire({
                title: "Oops...",
                text: response.body.message,
                icon: "error",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
              });
            }
          );
      } else {
        Swal.fire({
          title: "Oops...",
          text: "Code secret incorrecte",
          icon: "error",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
        });
      }
    },
    disconnect() {
      if (this.code == localStorage.getItem("code_secret")) {
        let session = localStorage;
        this.token_admin = session.getItem("token");

        let headers = { headers: { Authorization: this.token_admin } };

        this.$http
          .post(
            `${this.callback}/agent_collecteur/disconnect/${this.$route.params.id}`,
            {},
            headers
          )
          .then(
            (response) => {
              let data = response.body;

              Swal.fire({
                title: "Success...",
                text: data.message,
                icon: "success",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
              });
            },
            (response) => {
              Swal.fire({
                title: "Oops...",
                text: response.body.message,
                icon: "error",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
              });
            }
          );
      } else {
        Swal.fire({
          title: "Oops...",
          text: "Code secret incorrecte",
          icon: "error",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
        });
      }
    },
  },
};
</script>
