<template>
    <div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                token_admin: "",
                callback: 'http://essoapp.iziway.tk/api/auth/admin',
            }
        },
        mounted() {
            let session = localStorage

            session.clear('token')
                session.clear('id')
                session.clear('username')
                session.clear('code_secret')

                this.$router.push({ name: 'Login' })

            /*this.token_admin = session.getItem('token')


            let headers = {headers: {'Authorization': this.token_admin}}

            this.$http.post(`${this.callback}/logout`, {}, headers).then(response => {
                console.log(response);

                
            }, response => {
                // erreur console
            });*/
        }
    }
</script>