<template>
  <div>
    <Headers></Headers>

    <div class="main-container">
      <div class="xs-pd-20-10 pd-ltr-20">
        <div class="title pb-20">
          <h2 class="h3 mb-0">Clients</h2>
        </div>

        <div class="card card-body pb-10">
          <div class="h5 pd-20 mb-0">Liste des clients</div>
          <div class="row mt-4">
            <div class="col-md-8"></div>
            <div class="col-md-4">
              <div class="input-group align-items-center">
                <label for="">Recherche : </label>
                <input
                  type="search"
                  v-model="search_value"
                  @input="search"
                  class="ml-2 form-control form-control-sm"
                />
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="data-table table nowrap display">
              <thead class="">
                <tr>
                  <th>Date creation</th>
                  <th>Nom & Prenom</th>
                  <th>Numero de téléphone</th>
                  <th>Profession</th>
                  <th>Quartier</th>
                  <th>Nom collecteur</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="client in clients" :key="client.id">
                  <td>{{ client.created_at }}</td>
                  <td>{{ client.nom }} {{ client.prenom }}</td>
                  <td>{{ client.numero }}</td>
                  <td>{{ client.profession }}</td>
                  <td>{{ client.quartier }}</td>
                  <td>{{ client.collecteur }}</td>
                  <td>
                    <a
                      v-bind:href="'/clientdetail' + client.id"
                      class="btn btn-primary btn-sm"
                    >
                      <i class="fa fa-arrow-right"></i> Détail
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="input-group d-flex justify-content-end align-items-center"
            >
              <button @click="retro" class="mx-2 btn btn-light btn-sm">
                <i class="fa fa-arrow-left"></i> Précédent
              </button>
              <button @click="next" class="mx-2 btn btn-primary btn-sm">
                Suivant <i class="fa fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-responsive-bs4";
import Headers from "../layouts/header.vue";
export default {
  components: {
    Headers,
  },
  data() {
    return {
      token_admin: "",
      callback: "http://essoapp.iziway.tk/api/auth/admin",
      clients: [],
      search_value: null,
      nbr_client: 0,
      load: 0,
      row: 6,
      page: 1,
      total: null,
    };
  },
  mounted() {
    this.total = this.row * this.page;
    console.log(this.total);

    let session = localStorage;
    this.token_admin = session.getItem("token");

    let headers = {
      headers: {
        Authorization: this.token_admin,
      },
    };

    this.$http
      .post(
        `${this.callback}/client/list?row=${this.row}&page=${this.page}`,
        {},
        headers
      )
      .then(
        (response) => {
          let data = response.body;
          let obj = data.data;
          this.clients = Object.keys(obj).map(function (key) {
            return obj[key];
          });
          this.nbr_client = data.total;

          console.log(data);
          if (data.status == true) {
            this.load = 1;
            setTimeout(() => {
              $("table.display").DataTable({
                responsive: true,
                language: {
                  url: "https://cdn.datatables.net/plug-ins/1.10.21/i18n/French.json",
                },
                searching: false,
                paging: false,
                info: false,
              });
            }, 1000);
          }
        },
        (response) => {
          console.log(response);
        }
      );
  },
  methods: {
    retro() {
      this.search = null;
      this.load = 0;
      this.page = this.page - 1;
      this.total = this.row * this.page;
      console.log(this.page);
      this.clients = [];
      let session = localStorage;
      this.token_admin = session.getItem("token");

      let headers = {
        headers: {
          Authorization: this.token_admin,
        },
      };

      this.$http
        .post(
          `${this.callback}/client/list?row=${this.row}&page=${this.page}`,
          {},
          headers
        )
        .then(
          (response) => {
            let data = response.body;
            let obj = data.data;
            this.clients = Object.keys(obj).map(function (key) {
              return obj[key];
            });
            console.log(this.clients);
            if (data.status == true) {
              this.load = 1;
            }
          },
          (response) => {
            console.log(response);
          }
        );
    },

    next() {
      this.search_value = null;
      this.load = 0;
      this.page = this.page + 1;
      this.total = this.row * this.page;
      console.log(this.page);
      this.clients = [];
      let session = localStorage;
      this.token_admin = session.getItem("token");

      let headers = {
        headers: {
          Authorization: this.token_admin,
        },
      };

      this.$http
        .post(
          `${this.callback}/client/list?row=${this.row}&page=${this.page}`,
          {},
          headers
        )
        .then(
          (response) => {
            let data = response.body;
            let obj = data.data;
            this.clients = Object.keys(obj).map(function (key) {
              return obj[key];
            });
            console.log(this.clients);
            if (data.status == true) {
              this.load = 1;
            }
          },
          (response) => {
            console.log(response);
          }
        );
    },

    search() {
      console.log(this.search_value);

      this.page = 1;
      this.clients = [];
      let session = localStorage;
      this.token_admin = session.getItem("token");

      let headers = {
        headers: {
          Authorization: this.token_admin,
        },
      };

      this.$http
        .post(
          `${this.callback}/client/list?row=${this.row}&page=${this.page}&search=${this.search_value}`,
          {},
          headers
        )
        .then(
          (response) => {
            let data = response.body;
            let obj = data.data;
            this.clients = Object.keys(obj).map(function (key) {
              return obj[key];
            });
            console.log(this.clients);
            if (data.status == true) {
              this.load = 1;
            }
          },
          (response) => {
            console.log(response);
          }
        );
    },
  },
};
</script>