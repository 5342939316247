<template>
  <div>
    <Headers></Headers>

    <div class="main-container">
      <div class="xs-pd-20-10 pd-ltr-20">
        <div class="title pb-20">
          <h2 class="h3 mb-0">Détail du client</h2>
        </div>

        <div class="card card-body pb-10">
          <div class="row">
            <div class="my-4 col-md-5">
              <div class="row">
                <div class="col-md-12 d-flex justify-content-end">
                  <a href="/client" class="btn btn-dark btn-sm">
                    Retour
                  </a>
                </div>
                <div class="col-md-12 mt-4">
                  <p class="text-center text-primary">Information du client</p>
                </div>
                <div class="my-2 col-md-12">
                  <p>
                    Date de creation:
                    <strong>{{ client.created_at }}</strong>
                  </p>
                </div>
                <div class="my-2 col-md-12">
                  <p>
                    Nom & prenom:
                    <strong>{{ client.nom }} {{ client.prenom }}</strong>
                  </p>
                </div>
                <div class="my-2 col-md-12">
                  <p>
                    Numero de téléphone:
                    <strong>{{ client.numero }}</strong>
                  </p>
                </div>
                <div class="my-2 col-md-12">
                  <p>
                    Profession: <strong>{{ client.profession }}</strong>
                  </p>
                </div>
                <div class="mt-4 col-md-12">
                  <p class="text-center text-primary">Collecteur en charge</p>
                </div>
                <div class="my-2 col-md-12">
                  <p>
                    Nom & prenom:
                    <strong
                      >{{ collecteur.nom }} {{ collecteur.prenom }}</strong
                    >
                  </p>
                </div>
                <div class="my-2 col-md-12">
                  <p>
                    Numero de téléphone:
                    <strong>{{ collecteur.numero }}</strong>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <div class="my-4 col-md-12">
                <p class="text-left text-primary">Liste de carnet</p>
              </div>
              <div class="row" style="height: 550px; overflow-y: scroll">
                <div
                  class="mt-4 col-md-6"
                  v-for="carnet in carnets"
                  :key="carnet.id"
                >
                  <div class="p-4 border card bg-light" v-bind:id="carnet.id">
                    <h6 class="text-center">
                      {{ carnet.carnet.libelle }} -
                      {{ carnet.carnet.tarif }} Fcfa
                    </h6>
                    <p class="text-center">
                      <strong>{{ carnet.ids }}</strong
                      ><br />
                      <span
                        class="mx-1"
                        v-for="prod in carnet.produits"
                        :key="prod"
                        >+ {{ prod.produit }}
                      </span>
                    </p>
                    <span>
                      Date de debut:
                      <strong>{{ carnet.operations.premier }}</strong>
                    </span>
                    <span>
                      Nbr de cotisation:
                      <strong class="text-primary">{{
                        carnet.nbcotisattion
                      }}</strong>
                    </span>
                    <span>
                      Sommes:
                      <strong class="text-primary"
                        >{{
                          carnet.nbcotisattion * carnet.carnet.tarif
                        }}
                        Fcfa</strong
                      >
                    </span>
                    <span v-if="carnet.state == 1">
                      Status:
                      <strong
                        v-if="carnet.is_finished == 0"
                        class="text-primary"
                        ><i class="fa fa-ellipsis-h"></i> Encour</strong
                      >
                      <strong
                        v-if="carnet.is_finished == 1"
                        class="text-success"
                        ><i class="fa fa-check-double"></i> Finaliser</strong
                      >
                      <strong
                        v-if="carnet.is_delivred == 1"
                        class="text-success"
                        ><i class="fa fa-paper-plane"></i> Delivrer</strong
                      >
                    </span>

                    <span v-if="carnet.state == 0">
                      Status:
                      <strong class="text-danger"
                        ><i class="fa fa-times"></i> Suspendus</strong
                      >
                    </span>
                    <div
                      v-if="type == 7 || type == 1 || type == 2 || type == 5"
                    >
                      <button
                        class="btn btn-sm btn-danger"
                        data-toggle="collapse"
                        v-bind:href="'#susp' + carnet.id"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        v-if="
                          carnet.state == 1 &&
                          carnet.is_finished == 0 &&
                          type != 5
                        "
                      >
                        <i class="fa fa-times"></i>
                      </button>

                      <div class="collapse mt-2" v-bind:id="'susp' + carnet.id">
                        <div class="card card-body">
                          <form
                            action=""
                            method="post"
                            @submit.prevent="suspendre(carnet.id)"
                          >
                            <div class="form-group">
                              <textarea
                                name=""
                                id=""
                                cols="30"
                                rows="3"
                                placeholder="Motif de suspension"
                                class="form-control"
                              ></textarea>
                            </div>
                            <div class="form-group text-right">
                              <button
                                type="button"
                                class="btn btn-danger btn-sm"
                              >
                                Suspendre
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "datatables.net-responsive-bs4";
import Headers from "../layouts/header.vue";
import Swal from "sweetalert2";
export default {
  components: {
    Headers,
  },
  data() {
    return {
      token_admin: "",
      callback: "http://essoapp.iziway.tk/api/auth/admin",
      client: {},
      carnets: [{}],
      collecteur: {},
      type: 0,
      time_out: 0,
    };
  },
  mounted() {
    console.log(this.$route.params.id);

    var dateOffset = 24 * 60 * 60 * 1000 * 31;
    var myDate = new Date();
    this.time_out = myDate.setTime(myDate.getTime() - dateOffset);

    let session = localStorage;
    this.token_admin = session.getItem("token");
    this.type = session.getItem("type");

    let headers = { headers: { Authorization: this.token_admin } };

    this.$http
      .post(
        `${this.callback}/client/info/${this.$route.params.id}`,
        {},
        headers
      )
      .then(
        (response) => {
          let data = response.body.data;

          this.client = data;
          this.carnets = data.carnets;
          this.collecteur = data.collecteur;
        },
        (response) => {
          Swal.fire({
            title: "Oops...",
            text: response.body.message,
            icon: "error",
            showCancelButton: true,
            confirmButtonClass: "btn btn-success",
            cancelButtonClass: "btn btn-danger",
          });
        }
      );
  },
  methods: {
    suspendre(id) {
      let session = localStorage;
      this.token_admin = session.getItem("token");

      let headers = { headers: { Authorization: this.token_admin } };

      this.$http
        .post(
          `${this.callback}/carnet/exemplaire/change/state/${id}`,
          {},
          headers
        )
        .then(
          (response) => {
            Swal.fire({
              title: "Success...",
              text: response.body.message,
              icon: "success",
              showCancelButton: true,
              confirmButtonClass: "btn btn-success",
              cancelButtonClass: "btn btn-danger",
            });
            setTimeout(() => {
              window.location.reload();
            }, 2100);
          },
          (response) => {
            Swal.fire({
              title: "Oops...",
              text: response.body.message,
              icon: "error",
              showCancelButton: true,
              confirmButtonClass: "btn btn-success",
              cancelButtonClass: "btn btn-danger",
            });
          }
        );
    },
  },
};
</script>
