import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/auth/login.vue'
import Logout from '../views/auth/logout.vue'
import dashboard from '../views/home/home.vue'
import client_list from '../views/client/list.vue'
import client_new from '../views/client/nouveau.vue'
import client_detail from '../views/client/detail.vue'
import collecteur_list from '../views/collecteur/list.vue'
import collecteur_detail from '../views/collecteur/detail.vue'
import collecteur_depot from '../views/collecteur/depot.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },{
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: dashboard
  },
  {
    path: '/client',
    name: 'client_list',
    component: client_list
  },
  {
    path: '/clientnouveau',
    name: 'client_new',
    component: client_new
  },
  {
    path: '/clientdetail:id',
    name: 'client_detail',
    component: client_detail
  },
  {
    path: '/collecteur',
    name: 'collecteur_list',
    component: collecteur_list
  },
  {
    path: '/collecteurdetail:id',
    name: 'collecteur_detail',
    component: collecteur_detail
  },
  {
    path: '/collecteurdepot:id',
    name: 'collecteur_depot',
    component: collecteur_depot
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
