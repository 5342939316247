<template>
  <div>
    <Headers></Headers>

    <div class="main-container">
      <div class="xs-pd-20-10 pd-ltr-20">
        <div class="title pb-20">
          <h2 class="h3 mb-0">Collecteurs</h2>
        </div>

        <div class="card card-body pb-10">
          <div class="h5 pd-20 mb-0">Liste des collecteurs</div>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table display table-striped table-hover">
                  <thead class="">
                    <tr>
                      <th>Date creation</th>
                      <th>Nom & Prenom</th>
                      <th>Numero tèl</th>
                      <th>Username</th>
                      <th>Quartier</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="col in collecteurs" :key="col.id">
                      <td>{{ col.updated_at }}</td>
                      <td>{{ col.nom }} {{ col.prenom }}</td>
                      <td>{{ col.numero }}</td>
                      <td>{{ col.username }}</td>
                      <td>{{ col.quartier.libelle }}</td>
                      <td class="d-flex justify-content-center">
                        <a v-bind:href="'/collecteurdetail'+ col.id"
                          class="mx-2 btn btn-primary btn-sm"
                        >
                          Detail
                        </a>
                        <a
                          href="#"
                          v-if="col.is_active == 1"
                          class="mx-2 btn btn-danger btn-sm"
                          data-toggle="modal"
                          data-target="#delete"
                          @click="model(col.id, col.nom)"
                        >
                          Bloquée
                        </a>
                        <a
                          href="#"
                          v-if="col.is_active == 0"
                          class="mx-2 btn btn-success btn-sm"
                          @click="deblock(col.id)"
                        >
                          <i class="fas fa-times"></i> Debloquée
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="delete"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header no-bd">
            <h5 class="modal-title text-danger">Supprimer le carnet</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="text-center modal-body">
            <p>
              Voulez vous vraimernt block ce collecteur? <br /><strong
                id="libelle"
              ></strong>
            </p>
          </div>
          <div class="modal-footer no-bd">
            <button
              type="button"
              id="addRowButton"
              @click="block"
              class="btn btn-danger"
            >
              Oui, Bloquée
            </button>
            <button type="button" class="btn btn-light" data-dismiss="modal">
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-responsive-bs4";
import Headers from "../layouts/header.vue";
import Swal from "sweetalert2";
export default {
  components: {
    Headers,
  },
  data() {
    return {
      token_admin: "",
      callback: "http://essoapp.iziway.tk/api/auth/admin",
      collecteurs: [{}],
      nbr_collecteur: 0,
      quartier: null,
      id: null,
    };
  },
  mounted() {
    let session = localStorage;
    this.token_admin = session.getItem("token");

    let headers = { headers: { Authorization: this.token_admin } };

    this.$http.post(`${this.callback}/agent_collecteur/list`, {}, headers).then(
      (response) => {
        let data = response.body.data;
        this.collecteurs = data;
        this.nbr_collecteur = data.length;

        setTimeout(() => {
          $("table.display").DataTable({
            language: {
              url: "https://cdn.datatables.net/plug-ins/1.10.21/i18n/French.json",
            },
            scrollY: "300px",
          });
        }, 500);
      },
      (response) => {
        Swal.fire({
          title: "Oops...",
          text: response.body.message,
          icon: "error",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
        });
      }
    );
  },
  methods: {
    model(id, libelle) {
      this.id = id;
      $("#libelle").text(`"${libelle}"`);
    },
    block() {
      let session = localStorage;
      this.token_admin = session.getItem("token");

      let headers = { headers: { Authorization: this.token_admin } };

      this.$http
        .post(
          `${this.callback}/agent_collecteur/${this.id}/state/change`,
          {},
          headers
        )
        .then(
          (response) => {
            window.location.reload();
            console.log(response);
          },
          (response) => {
            Swal.fire({
              title: "Oops...",
              text: response.body.message,
              icon: "error",
              showCancelButton: true,
              confirmButtonClass: "btn btn-success",
              cancelButtonClass: "btn btn-danger",
            });
          }
        );
    },
    deblock(id) {
      let session = localStorage;
      this.token_admin = session.getItem("token");

      let headers = { headers: { Authorization: this.token_admin } };

      this.$http
        .post(
          `${this.callback}/agent_collecteur/${id}/state/change`,
          {},
          headers
        )
        .then(
          (response) => {
            Swal.fire({
                title: "Success...",
                text: response.body.message,
                icon: "success",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
              });
            setTimeout(() => {
              window.location.reload();
            }, 2100);
          },
          (response) => {
              Swal.fire({
                title: "Oops...",
                text: response.body.message,
                icon: "error",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success",
                cancelButtonClass: "btn btn-danger",
              });
          }
        );
    },
  },
};
</script>