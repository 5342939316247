<template>
  <div>
    <div class="pre-loader">
      <div class="pre-loader-box">
        <div class="loader-logo">
          <img src="/assets/vendors/images/deskapp-logo.svg" alt="" />
        </div>
        <div class="loader-progress" id="progress_div">
          <div class="bar" id="bar1"></div>
        </div>
        <div class="percent" id="percent1">0%</div>
        <div class="loading-text">Chargement...</div>
      </div>
    </div>
    <div class="header">
      <div class="header-left">
        <div class="menu-icon dw dw-menu"></div>
        <div
          class="search-toggle-icon dw dw-search2"
          data-toggle="header_search"
        ></div>
        <div class="header-search">
          <form>
            <div class="form-group mb-0">
              <i class="dw dw-search2 search-icon"></i>
              <input
                type="text"
                class="form-control search-input"
                placeholder="Search Here"
              />
              <div class="dropdown">
                <a
                  class="dropdown-toggle d-flex justify-content-start no-arrow"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                >
                  <i class="ion-arrow-down-c"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <div class="form-group row">
                    <label class="col-sm-12 col-md-2 col-form-label"
                      >From</label
                    >
                    <div class="col-sm-12 col-md-10">
                      <input
                        class="form-control form-control-sm form-control-line"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-12 col-md-2 col-form-label">To</label>
                    <div class="col-sm-12 col-md-10">
                      <input
                        class="form-control form-control-sm form-control-line"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-12 col-md-2 col-form-label"
                      >Subject</label
                    >
                    <div class="col-sm-12 col-md-10">
                      <input
                        class="form-control form-control-sm form-control-line"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="text-right">
                    <button class="btn btn-primary">Search</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="header-right">
        <div class="user-notification">
          <div class="dropdown">
            <a
              class="dropdown-toggle d-flex justify-content-start no-arrow"
              href="#"
              role="button"
              data-toggle="dropdown"
            >
              <i class="icon-copy dw dw-notification"></i>
              <span class="badge notification-active"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <div class="notification-list mx-h-350 customscroll">
                <ul>
                  <li>
                    <a href="#">
                      <img src="/assets/vendors/images/img.jpg" alt="" />
                      <h3>John Doe</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed...
                      </p>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="/assets/vendors/images/photo1.jpg" alt="" />
                      <h3>Lea R. Frith</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed...
                      </p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="user-info-dropdown">
          <div class="dropdown">
            <a
              class="
                dropdown-toggle
                d-flex
                justify-content-start
                align-items-center
              "
              href="#"
              role="button"
              data-toggle="dropdown"
            >
              <span class="user-icon">
                <img src="/assets/vendors/images/photo1.jpg" alt="" />
              </span>
              <span class="user-name mx-2">Ross C. Lopez</span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list"
            >
              <a class="dropdown-item" href="profile.html"
                ><i class="dw dw-user1"></i> Profile</a
              >
              <a class="dropdown-item" href="profile.html"
                ><i class="dw dw-settings2"></i> Setting</a
              >
              <a class="dropdown-item" href="faq.html"
                ><i class="dw dw-help"></i> Help</a
              >
              <router-link :to="{ name: 'logout' }" class="dropdown-item"
                ><i class="dw dw-logout"></i> Log Out</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="left-side-bar">
      <div class="brand-logo">
        <a href="/">
          <img
            src="/assets/vendors/images/deskapp-logo.svg"
            alt=""
            class="dark-logo"
          />
          <img
            src="/assets/vendors/images/deskapp-logo-white.svg"
            alt=""
            class="light-logo"
          />
        </a>
        <div class="close-sidebar" data-toggle="left-sidebar-close">
          <i class="ion-close-round"></i>
        </div>
      </div>
      <div class="menu-block customscroll">
        <div class="sidebar-menu">
          <ul id="accordion-menu">
            <li>
              <a
                href="/"
                class="dropdown-toggle no-arrow d-flex justify-content-start"
              >
                <span class="micon dw dw-house-1"></span
                ><span class="mtext">Tableau de bord</span>
              </a>
            </li>
            <li class="dropdown">
              <a
                href="#"
                class="dropdown-toggle d-flex justify-content-start"
                role="button"
                data-toggle="dropdown"
              >
                <span class="micon dw dw-user"></span
                ><span class="mtext">Clients</span>
              </a>
              <ul class="dropdown-menu dropdown-menu-right text-left w-100">
                <li>
                  <a class="dropdown-item" href="/client">Liste clients</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/clientnouveau"
                    >Nouveau client</a
                  >
                </li>
              </ul>
            </li>

            <li>
              <a
                href="/collecteur"
                class="dropdown-toggle d-flex justify-content-start no-arrow"
              >
                <span class="micon dw dw-user1"></span>
                <span class="mtext">Collecteurs</span>
              </a>
            </li>

            <li class="dropdown">
              <a
                href="#"
                class="dropdown-toggle d-flex justify-content-start"
                role="button"
                data-toggle="dropdown"
              >
                <span class="micon dw dw-library"></span
                ><span class="mtext">Chef zones</span>
              </a>
              <ul class="dropdown-menu dropdown-menu-right text-left w-100">
                <li>
                  <a class="dropdown-item" href="basic-table.html"
                    >Liste des demandes</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="datatable.html">Historiques</a>
                </li>
                <li>
                  <a class="dropdown-item" href="datatable.html"
                    >Liste des transaction</a
                  >
                </li>
              </ul>
            </li>
            <li>
              <a
                href="calendar.html"
                class="dropdown-toggle d-flex justify-content-start no-arrow"
              >
                <span class="micon dw dw-calendar1"></span
                ><span class="mtext">Agences</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="mobile-menu-overlay"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reload: true,
    };
  },
  mounted() {},
};
</script>